import React, { useState, useRef } from "react";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styles from "../../styles/age-gate/age-gate-main.module.scss";
import logo from "../../svg/logo-badge-white.svg";
import horizontalLogo from "../../svg/logo-horizontal-white.svg";
import { calculateMonth, toNumbers, calculateDate } from "../../utils/ageGate";

const AgeGateMain = ({ location }) => {
    const [date, setDate] = useState({
        dd: ["", ""],
        mm: ["", ""],
        aaaa: ["", "", "", ""],
    });
    const [calendar, setCalendar] = useState("AÑO");
    // const [remember, setRemember] = useState(false)
    const [disabled, setDisabled] = useState(true);
    const inputYear1 = useRef(null);
    const inputYear2 = useRef(null);
    const inputYear3 = useRef(null);
    const inputYear4 = useRef(null);
    const inputMonth1 = useRef(null);
    const inputMonth2 = useRef(null);
    const inputDay1 = useRef(null);
    const inputDay2 = useRef(null);

    const underAgeLink =
        "https://www.cerveceriaymalteriaquilmes.com/consumo-responsable";

    let newPath = location.state
        ? location.state.newPath
            ? location.state.newPath
            : "/"
        : "/";

    const handleAge = (e) => {
        let { name, value } = e.target;
        let newDate = { ...date };
        //YEAR
        if (name === "A1") {
            if (value.length === 1 && e.target.validity.valid) {
                inputYear2.current.focus();
                newDate.aaaa[0] = value;
                setDate(newDate);
            } else {
                newDate.aaaa[0] = "";
                setDisabled(true);
                setDate(newDate);
            }
        }
        if (name === "A2") {
            if (value.length === 1 && e.target.validity.valid) {
                inputYear3.current.focus();
                newDate.aaaa[1] = value;
                setDate(newDate);
            } else {
                newDate.aaaa[1] = "";
                setDate(newDate);
                setDisabled(true);
                inputYear1.current.focus();
            }
        }
        if (name === "A3") {
            if (value.length === 1 && e.target.validity.valid) {
                newDate.aaaa[2] = value;
                setDate(newDate);
                inputYear4.current.focus();
            } else {
                newDate.aaaa[2] = "";
                setDate(newDate);
                setDisabled(true);
                inputYear2.current.focus();
            }
        }
        if (name === "A4") {
            if (value.length === 1 && e.target.validity.valid) {
                newDate.aaaa[3] = value;
                setDate(newDate);
                checkAge(newDate, "year");
            } else {
                newDate.aaaa[3] = "";
                setDate(newDate);
                setDisabled(true);
                inputYear3.current.focus();
            }
        }
        //MONTH
        if (name === "M1") {
            if (value.length === 1 && e.target.validity.valid) {
                inputMonth2.current.focus();
                newDate.mm[0] = value;
                setDate(newDate);
            } else {
                newDate.mm[0] = "";
                setDate(newDate);
                setDisabled(true);
            }
        }
        if (name === "M2") {
            if (value.length === 1 && e.target.validity.valid) {
                newDate.mm[1] = value;
                setDate(newDate);
                checkAge(newDate, "month");
            } else {
                newDate.mm[1] = "";
                setDate(newDate);
                setDisabled(true);
                inputMonth1.current.focus();
            }
        }
        //DAY
        if (name === "D1") {
            if (value.length === 1 && e.target.validity.valid) {
                inputDay2.current.focus();
                newDate.dd[0] = value;
                setDate(newDate);
            } else {
                newDate.dd[0] = "";
                setDate(newDate);
                setDisabled(true);
            }
        }
        if (name === "D2") {
            if (value.length === 1 && e.target.validity.valid) {
                newDate.dd[1] = value;
                setDate(newDate);
                checkAge(newDate, "day");
            } else {
                newDate.dd[1] = "";
                setDate(newDate);
                setDisabled(true);
                inputDay1.current.focus();
            }
        }
    };

    const checkAge = (date, who) => {
        let birthday = new Date();
        if (who === "year") {
            let year = birthday.getUTCFullYear();
            if (year - toNumbers(date.aaaa) < 18) {
                window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Age Gate ",
                    event_action: "Interaction",
                    event_label: "No",
                    interaction: false,
                    component_name: "full_age_gate",
                    element_text: "full_age_gate",
                });
                window.location.href = underAgeLink;
            }
            if (year - toNumbers(date.aaaa) >= 19) {
                if (date.aaaa.length === 4) {
                    sessionStorage.setItem("age", "true");
                    setDisabled(false);
                    window.dataLayer.push({
                        event: "GAEvent",
                        event_category: "Age Gate",
                        event_action: "Interaction",
                        event_label: "Yes",
                        interaction: false,
                        component_name: "",
                        element_text: "",
                    });
                } else {
                    sessionStorage.clear();
                    setDisabled(true);
                }
            } else {
                setCalendar("MES");
                setTimeout(() => {
                    inputMonth1.current.focus();
                }, 1);
            }
        }
        if (who === "month") {
            birthday.setDate(1);
            birthday.setMonth(toNumbers(date.mm) - 1);
            birthday.setFullYear(toNumbers(date.aaaa));
            let today = new Date();
            if (toNumbers(date.mm) - 1 > today.getMonth()) {
                setDate({
                    dd: ["", ""],
                    mm: ["", ""],
                    aaaa: ["", "", "", ""],
                });
                window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Age Gate ",
                    event_action: "Interaction",
                    event_label: "No",
                    interaction: false,
                    component_name: "full_age_gate",
                    element_text: "full_age_gate",
                });
                return (window.location.href = underAgeLink);
            }
            if (calculateMonth(birthday)) {
                sessionStorage.setItem("age", "true");
                setDisabled(false);
                window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Age Gate",
                    event_action: "Interaction",
                    event_label: "Yes",
                    interaction: false,
                    component_name: "",
                    element_text: "",
                });
            } else {
                setCalendar("DÍA");
                setTimeout(() => {
                    inputDay1.current.focus();
                }, 1);
            }
        }
        if (who === "day") {
            birthday.setDate(toNumbers(date.dd));
            birthday.setMonth(toNumbers(date.mm) - 1);
            birthday.setFullYear(toNumbers(date.aaaa));
            if (calculateDate(birthday)) {
                sessionStorage.setItem("age", "true");
                setDisabled(false);
                window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Age Gate",
                    event_action: "Interaction",
                    event_label: "Yes",
                    interaction: false,
                    component_name: "",
                    element_text: "",
                });
            } else {
                setCalendar("AÑO");
                setDisabled(false);
                window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Age Gate ",
                    event_action: "Interaction",
                    event_label: "No",
                    interaction: false,
                    component_name: "full_age_gate",
                    element_text: "full_age_gate",
                });
                window.location.href = underAgeLink;
            }
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        // remember &&
        localStorage.setItem("age", "true");
        window.dataLayer.push({
            event: "GAEvent",
            event_category: "Age Gate",
            event_action: "Interaction",
            event_label: "Send",
            interaction: false,
            component_name: "",
            element_text: "",
        });
        return navigate(newPath, { replace: true });
    };

    return (
        <div className={styles.ageGateContainer}>
            <div className={styles.ageGateBackground}></div>
            <StaticImage
                src="../../images/overlay-01.png"
                height={1080}
                quality={100}
                formats={["AUTO"]}
                alt="Overlay"
                placeholder="none"
                objectFit="cover"
                className={styles.ageGateOverlay}
            />
            {[...Array(2)].map((item, index) => (
                <div
                    className={styles.test}
                    style={{
                        position: "absolute",
                        top: index * 100 + "vh",
                        width: "100vw",
                        height: "100vh",
                    }}
                >
                    <StaticImage
                        src="../../images/12.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Overlay"
                        placeholder="none"
                        objectFit="cover"
                        className={styles.ageGateImage01}
                    />
                    <StaticImage
                        src="../../images/15.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Overlay"
                        placeholder="none"
                        objectFit="cover"
                        className={styles.ageGateImage02}
                    />
                    <StaticImage
                        src="../../images/13.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Overlay"
                        placeholder="none"
                        objectFit="cover"
                        className={styles.ageGateImage03}
                    />
                    <StaticImage
                        src="../../images/10.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Overlay"
                        placeholder="none"
                        objectFit="cover"
                        className={styles.ageGateImage04}
                    />
                    <StaticImage
                        src="../../images/21.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Overlay"
                        placeholder="none"
                        objectFit="cover"
                        className={styles.ageGateImage05}
                    />
                    <StaticImage
                        src="../../images/19.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Overlay"
                        placeholder="none"
                        objectFit="cover"
                        className={styles.ageGateImage06}
                    />
                    <StaticImage
                        src="../../images/11.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Overlay"
                        placeholder="none"
                        objectFit="cover"
                        className={styles.ageGateImage07}
                    />
                    <StaticImage
                        src="../../images/16.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Overlay"
                        placeholder="none"
                        objectFit="cover"
                        className={styles.ageGateImage08}
                    />
                    <StaticImage
                        src="../../images/14.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Overlay"
                        placeholder="none"
                        objectFit="cover"
                        className={styles.ageGateImage09}
                    />
                </div>
            ))}
            <img className={styles.ageGateLogo} src={logo} alt="Goose Island" />
            <div className={styles.ageGateTitle}>
                ¿En qué {calendar} naciste?
            </div>
            <div className={styles.ageGateSubtitle}>No nos mientas, eh</div>
            <form className={styles.ageGateForm} onSubmit={(e) => onSubmit(e)}>
                {calendar === "AÑO" && (
                    <div className={styles.ageGateInputContainer}>
                        <input
                            required
                            maxLength={1}
                            max="2"
                            name={"A1"}
                            placeholder="_"
                            onChange={handleAge}
                            value={date.aaaa[0]}
                            ref={inputYear1}
                            type="text"
                            pattern="[0-9]*"
                            className={styles.ageGateInput}
                        />
                        <input
                            required
                            maxLength="1"
                            max="9"
                            name={"A2"}
                            onChange={handleAge}
                            placeholder="_"
                            value={date.aaaa[1]}
                            ref={inputYear2}
                            type="text"
                            pattern="[0-9]*"
                            className={styles.ageGateInput}
                        />
                        <input
                            required
                            maxLength={1}
                            max={9}
                            name={"A3"}
                            onChange={handleAge}
                            placeholder="_"
                            value={date.aaaa[2]}
                            ref={inputYear3}
                            type="text"
                            pattern="[0-9]*"
                            className={styles.ageGateInput}
                        />
                        <input
                            required
                            maxLength={1}
                            max={9}
                            name={"A4"}
                            onChange={handleAge}
                            placeholder="_"
                            value={date.aaaa[3]}
                            ref={inputYear4}
                            type="text"
                            pattern="[0-9]*"
                            className={styles.ageGateInput}
                        />
                    </div>
                )}
                {calendar === "MES" && (
                    <div className={styles.ageGateNarrowInputContainer}>
                        <input
                            required
                            maxLength={1}
                            max={1}
                            name={"M1"}
                            onChange={handleAge}
                            placeholder="_"
                            value={date.mm[0]}
                            ref={inputMonth1}
                            type="text"
                            pattern="[0-9]*"
                            className={styles.ageGateInput}
                        />
                        <input
                            required
                            maxLength={1}
                            max={2}
                            name={"M2"}
                            onChange={handleAge}
                            placeholder="_"
                            value={date.mm[1]}
                            ref={inputMonth2}
                            type="text"
                            pattern="[0-9]*"
                            className={styles.ageGateInput}
                        />
                    </div>
                )}
                {calendar === "DÍA" && (
                    <div className={styles.ageGateNarrowInputContainer}>
                        <input
                            max={3}
                            required
                            name={"D1"}
                            type="text"
                            pattern="[0-9]*"
                            maxLength={1}
                            ref={inputDay1}
                            placeholder="_"
                            value={date.dd[0]}
                            onChange={handleAge}
                            className={styles.ageGateInput}
                        />
                        <input
                            max={9}
                            required
                            name={"D2"}
                            type="text"
                            pattern="[0-9]*"
                            maxLength={1}
                            placeholder="_"
                            ref={inputDay2}
                            value={date.dd[1]}
                            onChange={handleAge}
                            className={styles.ageGateInput}
                        />
                    </div>
                )}
                {/* <div className={styles.ageGateRememberMe}>
          <input
            className={styles.ageGateRadio}
            type="radio"
            checked={remember}
            onClick={() => {
              setRemember(!remember)
            }}
          />
          <div className={styles.ageGateRememberMeText}>
            Recordame (no marcar si compartís la computadora)
          </div>
        </div> */}
                <button
                    className={`${styles.ageGateButton}
          ${disabled && styles.ageGateDisabledButton}`}
                    type="submit"
                >
                    Ingresar
                </button>
            </form>
            <img
                className={styles.ageGateBottomLogo}
                src={horizontalLogo}
                alt="Goose Island"
            />
            <div className={styles.ageGateWarning}>
                Beber con moderación. Prohibida su venta a menores de 18 años.
                No comparta el contenido con menores.
            </div>
        </div>
    );
};

export default AgeGateMain;
