import React from "react"
import AgeGateMain from "../components/age-gate/age-gate-main.jsx"
import Seo from "../components/seo"

const AgeGatePage = ({ location }) => {
  return (
    <>
      <Seo title="Goose Island" description="" />
      <AgeGateMain location={location} />
    </>
  )
}

export default AgeGatePage
